.share_heading_icon {
  cursor: pointer;
}

.card{
    border: none !important;
}

.airdrop_card {
    background-color: white;
    border: none !important;
    border-radius: 25px !important;
    box-shadow: 0 16px 50px 0 #0000001a;
}

.social_link_card{
    min-height: 115px;
    max-height: 115px;
}

.wap_share_icon{
    cursor: pointer;
}
