.timer_expire_text {
  font-family: var(--primary-poppins-font);
  font-size: 2.25em;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 44px;
}

.clock {
  text-align: center;
}

.flip-clock__piece {
  display: inline-block;
  margin: 0 5px;
}

.card_timer {
  display: block;
  position: relative;
  padding-bottom: 0.32em;
  font-size: 45px;
  line-height: 0.95;
}

.card_top {
  display: block;
  color: white;
  width: 1.8em;
  background-color: rgb(76, 74, 74);
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 7px;
}

.timer_label {
  font-family: var(--primary-poppins-font);
  font-size: 1.1em;
  font-style: normal;
  font-weight: 400;
}

@media (min-width: 320px) and (max-width: 424px) {
  .card_timer {
    display: block;
    position: relative;
    padding-bottom: 0.1em;
    font-size: 30px;
    line-height: 0.95;
  }

  .timer_expire_text {
    margin-top: 20px;
    font-family: var(--primary-poppins-font);
    font-size: 1.6em;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .flip-clock__slot{
    margin-top: 80px;
  }

  .timer_label {
    font-family: var(--primary-poppins-font);
    font-size: 0.85em;
    font-style: normal;
    font-weight: 400;
  }

  .card_timer {
    margin-top: 5px;
    display: block;
    position: relative;
    padding-bottom: 0.72em;
    font-size: 30px;
    line-height: 0.95;
  }

  .timer_expire_text {
    margin-top: 60px;
    font-family: var(--primary-poppins-font);
    font-size: 1.6em;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 65px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .card_timer {
    margin-top: 20px;
    display: block;
    position: relative;
    padding-bottom: 0.72em;
    font-size: 34px;
    line-height: 0.95;
  }

  .timer_expire_text {
    margin-top: 30px;
    font-family: var(--primary-poppins-font);
    font-size: 1.8em;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 25px;
  }
}
