.card {
  border: none !important;
}

.airdrop_description_card {
  background-color: white;
  border: none !important;
  border-radius: 25px !important;
  box-shadow: 0 16px 50px 0 #0000001a;
  padding: 20px;
}

.project_description_card {
  min-height: 200px;
  max-height: 200px;
}

.description{
  min-height: 160px;
  max-height: 160px;
  overflow: auto;
}

.description::-webkit-scrollbar {
    width: 5px;
}
 
.description::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.description::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d; 
}

.description_heading {
  font-family: var(--primary-poppins-font);
  font-size: 1.50em;
  font-style: normal;
  font-weight: 400;
}

.description_text {
  font-family: var(--primary-poppins-font);
  font-size: 0.95em;
  font-style: normal;
  font-weight: 400;
  padding-right: 8px;
}
