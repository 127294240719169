.status_card {
  position: relative;
  background-color: white;
  border: none !important;
  border-radius: 25px !important;
  box-shadow: 0 16px 50px 0 #0000001a;
  min-height: 400px;
  max-height: 600px;
}

.card {
  border: none !important;
}

.status_text {
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--primary-poppins-font);
  font-size: 1.25em;
  font-style: normal;
  font-weight: 500;
  color: green;
  display: inline-block;
}

.status_text::before {
  content: '\2022';
  margin-right: 1px;
  font-size: 35px;
  display: inline-block;
  vertical-align: middle;
}

.claim_now_button{
  background-color: var(--primary-blue-color);
  font-family: var(--primary-poppins-font);
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 1em;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.claim_now_btn_disabled {
  background-color: var(--primary-blue-color);
  font-family: var(--primary-poppins-font);
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 1em;
  opacity: 0.6;
  pointer-events: none;
}
.claim_now_btn:hover {
  background: linear-gradient(to right, #0d7dd2 25%, #406aa8 75%);
}
hr {
  margin: 0 !important;
}
.start_end_time {
  font-family: var(--primary-poppins-font);
  font-size: 0.95em;
  font-style: normal;
  font-weight: 400;
}
.start_end_time p {
  padding: 0;
  margin: 0;
}
.timer {
  margin-top: 100px;
}
.airdrop_time {
  margin-top: 55px;
}
.status_img {
  width: '30px';
  margin-top: '30px';
}

@media (min-width: 992px) and (max-width: 1199px) {
  .start_end_time {
    font-family: var(--primary-poppins-font);
    font-size: 0.85em;
    font-style: normal;
    font-weight: 400;
  }
  .timer {
    margin-top: 50px;
  }
  .airdrop_time {
    margin-top: 40px;
  }
  .claim_now_button {
    background-color: var(--primary-blue-color);
    font-family: var(--primary-poppins-font);
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
    border-radius: 30px;
    color: var(--white);
    font-size: 1em;
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .status_img {
    width: '50px';
    margin-top: '50px';
  }
}

@media (min-width: 1200px) and (max-width: 1400px){
  .airdrop_time {
    margin-top: 35px;
  }
}
