.claimed_airdrop_card {
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  background-color: white;
  border: none !important;
  border-radius: 25px !important;
  box-shadow: 0 16px 50px 0 #0000001a;
}

.claimed_airdrop_list_card {
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  background-color: white;
  border: none !important;
  border-radius: 25px !important;
  box-shadow: 0 16px 50px 0 #0000001a;
  padding: 15px;
}

.claimed_airdrop_list_card::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.claimed_airdrop_list_card::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.claimed_airdrop_list_card::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d; 
}

.claimed_airdrop_number {
  font-family: var(--primary-poppins-font);
  font-size: 3.95em;
  font-style: normal;
  font-weight: 400;
}

.claimed_airdrop_text {
  font-family: var(--primary-poppins-font);
  font-size: 1.25em;
  font-style: normal;
  font-weight: 400;
}

.no_claim_text {
  font-family: var(--primary-poppins-font);
  font-size: 1.25em;
  font-style: normal;
  font-weight: 400;
}

.address_list_text {
  font-family: var(--primary-poppins-font);
  font-size: 1.25em;
  font-style: normal;
  font-weight: 400;
}

.address_text {
  font-family: var(--primary-poppins-font);
  font-size: 0.85em;
  font-style: normal;
  font-weight: 400;
}
